/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linkedin': {
    width: 35,
    height: 34,
    viewBox: '0 0 35 34',
    data: '<path pid="0" d="M29.467 28.974h-5.035v-7.89c0-1.881-.038-4.302-2.624-4.302-2.625 0-3.026 2.047-3.026 4.163v8.029h-5.035V12.75h4.837v2.211h.065c.676-1.275 2.319-2.62 4.774-2.62 5.101 0 6.045 3.357 6.045 7.728v8.905h-.001zM8.06 10.53a2.921 2.921 0 0 1-2.923-2.925 2.924 2.924 0 1 1 2.923 2.925zm2.524 18.444H5.536V12.75h5.05v16.224zM31.985 0H3.01C1.622 0 .5 1.097.5 2.45v29.1C.5 32.906 1.622 34 3.009 34H31.98c1.386 0 2.519-1.095 2.519-2.45V2.45C34.5 1.095 33.367 0 31.981 0h.004z"/>'
  }
})
