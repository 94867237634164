<template>
  <div class="fixed pin-t pin-b pin-l pin-r w-full h-full bg-firefly-90 z-50 flex justify-center">
    <div class="w-full">
      <div class="mx-30p my-15p md:mx-100p lg:mx-100p xl:mx-200p" v-click-outside="onClickOutside">
        <div class="flex justify-end">
          <div
            @click="onClickOutside"
            class="text-white inline-block uppercase font-bold font-sans pb-20p text-blue-lighter cursor-pointer hover:text-white"
          >
            Close
          </div>
        </div>
        <vue-plyr :options="{ controls: ['play-large', 'progress', 'fullscreen', 'volume', 'mute'] }">
          <div class="plyr__video-embed">
            <iframe
              src="https://player.vimeo.com/video/280993422?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"
              allowfullscreen
              allowtransparency
              allow="autoplay"
            >
            </iframe>
          </div>
        </vue-plyr>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClickOutside(e) {
      if (e.type === 'touchend' || e.type === 'click') {
        if (e.target.className != 'link') {
          this.$emit('active', false)
        }
      }
    },
  },
}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 600ms ease;
}
</style>
