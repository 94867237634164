/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wave-base': {
    width: 16,
    height: 16,
    viewBox: '0 0 322.45 112.56',
    data: '<g data-name="Layer 2"><path pid="0" d="M322.45 112.56H0V24.69s85.51 15.11 159.86 10C258.9 27.92 322.45 0 322.45 0z" _fill="#152735" data-name="Layer 1"/></g>'
  }
})
