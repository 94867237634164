/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'instagram': {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35',
    data: '<path pid="0" d="M17.5 3.153c4.673 0 5.226.018 7.071.102 1.707.078 2.633.363 3.25.603.817.317 1.4.696 2.012 1.309a5.422 5.422 0 0 1 1.31 2.012c.239.617.524 1.543.602 3.25.084 1.845.102 2.398.102 7.071s-.018 5.226-.102 7.072c-.078 1.706-.363 2.632-.603 3.25a5.422 5.422 0 0 1-1.309 2.011 5.422 5.422 0 0 1-2.012 1.31c-.617.239-1.543.524-3.25.602-1.845.084-2.398.102-7.071.102s-5.226-.018-7.072-.102c-1.706-.078-2.632-.363-3.249-.603a5.421 5.421 0 0 1-2.012-1.309 5.422 5.422 0 0 1-1.31-2.012c-.239-.617-.524-1.543-.602-3.25-.084-1.845-.102-2.398-.102-7.071s.018-5.226.102-7.071c.078-1.707.363-2.633.603-3.25.317-.817.696-1.4 1.309-2.012a5.422 5.422 0 0 1 2.012-1.31c.617-.239 1.543-.524 3.25-.602 1.845-.084 2.398-.102 7.071-.102zM17.5 0c-4.753 0-5.349.02-7.215.105C8.422.19 7.15.486 6.037.92a8.578 8.578 0 0 0-3.1 2.018 8.578 8.578 0 0 0-2.018 3.1C.486 7.15.19 8.422.105 10.285.02 12.15 0 12.747 0 17.5s.02 5.349.105 7.215c.085 1.863.381 3.135.814 4.248a8.579 8.579 0 0 0 2.018 3.1 8.578 8.578 0 0 0 3.1 2.018c1.113.433 2.385.729 4.248.814 1.866.085 2.462.105 7.215.105s5.349-.02 7.215-.105c1.863-.085 3.135-.381 4.248-.814a8.578 8.578 0 0 0 3.1-2.018 8.578 8.578 0 0 0 2.018-3.1c.433-1.113.729-2.385.814-4.248.085-1.866.105-2.462.105-7.215s-.02-5.349-.105-7.215c-.085-1.863-.381-3.135-.814-4.248a8.578 8.578 0 0 0-2.018-3.1 8.578 8.578 0 0 0-3.1-2.018C27.85.486 26.578.19 24.715.105 22.85.02 22.253 0 17.5 0zm0 8.514a8.987 8.987 0 1 0 0 17.973 8.987 8.987 0 0 0 0-17.973zm0 14.82a5.833 5.833 0 1 1 0-11.667 5.833 5.833 0 0 1 0 11.666zM28.941 8.157a2.1 2.1 0 1 1-4.2 0 2.1 2.1 0 0 1 4.2 0z"/>'
  }
})
