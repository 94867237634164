<template>
  <section class="bg-white pt-20p">

    <div class="container hidden md:block mt-30p">
      <div class="flex -ml-8p -mr-8p">
        <div class="w-1/5 px-8p">
          <neighborhood-box @click.native="active = dine" name="Dine" color="lime"></neighborhood-box>
        </div>

        <div class="w-1/5 px-8p">
          <neighborhood-box @click.native="active = live" name="Live" color="pacific"></neighborhood-box>
        </div>

        <div class="w-1/5 px-8p">
          <neighborhood-box @click.native="active = stay" name="Stay" color="elm"></neighborhood-box>
        </div>

        <div class="w-1/5 px-8p">
          <neighborhood-box @click.native="active = shop" name="Shop" color="lagoon"></neighborhood-box>
        </div>

        <div class="w-1/5 px-8p">
          <neighborhood-box @click.native="active = enjoy" name="Enjoy" color="firefly"></neighborhood-box>
        </div>
      </div>
    </div>

    <div class="container block md:hidden flex flex-wrap justify-center">

          <div class="flex m-5p w-2/7">
            <div class="w-full">
              <neighborhood-box @click.native="active = dine" name="Dine" color="lime"></neighborhood-box>
            </div>
          </div>

          <div class="flex m-5p w-2/7">
            <div class="w-full">
              <neighborhood-box @click.native="active = live" name="Live" color="pacific"></neighborhood-box>
            </div>
          </div>

          <div class="flex m-5p w-2/7">
            <div class="w-full">
              <neighborhood-box @click.native="active = stay" name="Stay" color="elm"></neighborhood-box>
            </div>
          </div>

          <div class="flex m-5p w-2/7">
            <div class="w-full">
              <neighborhood-box @click.native="active = shop" name="Shop" color="lagoon"></neighborhood-box>
            </div>
          </div>

          <div class="flex m-5p w-2/7">
            <div class="w-full">
              <neighborhood-box @click.native="active = enjoy" name="Enjoy" color="firefly"></neighborhood-box>
            </div>
          </div>

    </div>

    <div class="container px-0 md:px-8 lg:px-11 xl:px-14 pb-75p">
      
      <div class="relative mt-15p md:mt-50p">
        
        <div class="flex w-full">
          <div class="bg-white md:pr-35p">
            <img :src="activeMap">
          </div>
  
          <div class="flex-no-shrink w-150p hidden md:block">
            <swiper
              v-for="category in categories" :key="category.map"
              v-if="active.name === category.name"
              :images="category.logos ? category.logos : null"
              style="filter: grayscale(100%) contrast(150%) opacity(60%);"
            >
            </swiper>
            <swiper
              v-if="typeof active === 'string'"
              :images="allLogos"
              style="filter: grayscale(100%) contrast(150%) opacity(60%);"
            >
            </swiper>

          </div>
        </div>

      </div>

    </div>

  </section>
</template>

<script>
  export default {
    props: ['dine', 'live', 'stay', 'shop', 'enjoy', 'all'],
    created () {
      this.active = this.all

      this.allLogos = this.shuffle(this.allLogos.concat(this.dine.logos, this.live.logos, this.stay.logos, this.shop.logos, this.enjoy.logos))
    },
    computed: {
      activeMap () {
        return this.active.map || this.active
      }
    },
    data () {
      return {
        active: 'dine',
        categories: [this.dine, this.live, this.stay, this.shop, this.enjoy],
        allLogos: []
      }
    },
    methods: {
      shuffle (a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
      }
    }
  }
</script>

<style lang="scss" scoped>

#dine {
  background-image: url('/assets/Coffee.jpg');
  background-size: auto 110%;
  transition: background-size 100ms ease-in-out;
  &:hover {
    background-size: auto 100%;
  }
}
#live {
  background-image: url('/assets/Live-photo.jpg');
  background-size: auto 110%;
  transition: background-size 100ms ease-in-out;
  &:hover {
    background-size: auto 100%;
  }
}
#stay {
  background-image: url('/assets/Stay-image.jpg');
  background-size: auto 120%;
  transition: background-size 100ms ease-in-out;
  &:hover {
    background-size: auto 110%;
  }
}
#shop {
  background-image: url('/assets/Shop-image.jpg');
  background-size: auto 110%;
  transition: background-size 100ms ease-in-out;
  &:hover {
    background-size: auto 100%;
  }
}
#enjoy {
  background-image: url('/assets/Enjoy-image.jpg');
  background-size: auto 110%;
  transition: background-size 100ms ease-in-out;
  &:hover {
    background-size: auto 100%;
  }
}
</style>