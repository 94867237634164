<template>
  <div class="hello-world container my-10">

    <h2>{{ msg }}</h2>

    <div class="leading-normal mb-6">
      <p>You're viewing the <code>&#060;HelloWorld&#062;</code> component, with data being passed from twig, this paragraph being orange shows the power of scoped styles in a Vue component.</p>
    </div>

    <div class="py-4 px-6 inline-block rounded shadow bg-black text-left">
      <code class="text-white">
        <dl>
          <span class="flex justify-between">
            <dt class="pr-12">Craft Version: <span class="text-grey-darker">(via prop)</span></dt>
            <dd class="text-orange-dark">{{ info.craftVersion }}</dd>
          </span>
          <span class="flex justify-between">
            <dt class="pr-12">Environment: <span class="text-grey-darker">(via prop)</span></dt>
            <dd class="text-orange-dark">{{ info.environment }}</dd>
          </span>
          <span class="flex justify-between">
            <dt class="pr-12">DB Name: <span class="text-grey-darker">(via slot)</span></dt>
            <dd class="text-orange-dark"><slot></slot></dd>
          </span>
        </dl>
      </code>
    </div>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: ['info'],
  data () {
    return {
      msg: 'Vue Component'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>