/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-arrow': {
    width: 16,
    height: 16,
    viewBox: '0 0 190.83 190.83',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" _fill="none" _stroke="#80bc00" stroke-miterlimit="10" stroke-width="5"><path pid="0" d="M79.52 24.12l71.55 71.56-71.03 71.02"/><circle pid="1" cx="95.41" cy="95.41" r="92.91"/></g></g>'
  }
})
