/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wave-2': {
    width: 1520,
    height: 221,
    viewBox: '0 0 1520 221',
    data: '<g _stroke="#00A7E1" stroke-width="3" _fill="none" fill-rule="evenodd"><path pid="0" d="M1.4 219.6C613.2-16.9 1183.6-10.8 1518.2 13.5M95.5 219.2C671.9 17.7 1202 24.7 1519.3 47.8M197.9 219.6C733.6 53 1219.7 60.3 1518.3 81.9M316.3 219.5C804.7 88.4 1241.9 96.1 1518.2 116.1M456.9 219.3c431.5-95.2 812.3-87.1 1061.9-69M634.3 219.4c357.5-58.7 669.2-50.5 883.9-34.9"/></g>'
  }
})
