<template>
  <div class="relative aspect-ratio-4/3 lg:aspect-ratio-16/9 mb-20p">
    <div v-for="floor in tower.floors" :key="floor.floorNumber">
      <transition name="floor-fade">
        <div
          v-show="selected === floor"
          class="absolute pin-t pin-b pin-l pin-r bg-center bg-cover"
          :style="`background-image: url('${floor.buildingImage}');`">
        </div>
      </transition>

      <transition name="floor-fade">
        <div
          v-show="selected === '' || selected === null"
          class="absolute pin-t pin-b pin-l pin-r bg-center bg-cover"
          :style="`background-image: url('/assets/Space-Central_image-1.jpg');`">
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['tower', 'selected']
  }
</script>

<style scoped>
  .floor-fade-enter,
  .floor-fade-leave-to {
    opacity: 0;
  }
  .floor-fade-enter-active {
    transition: all 300ms ease;
    z-index: 10;
  }
  .floor-fade-leave-active {
    transition: all 150ms ease 200ms;
    z-index: 0;
  }
  .floor-fade-leave-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
</style>