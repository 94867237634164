<template>
  <div :class="[homepage ? 'homepage' : '', scrolled ? 'scrolled' : '', animationFinished ? 'finished' : '']">

    <!-- sticky nav header -->
    <div v-if="homepage && scrolled" class="bg-firefly cursor-pointer md:hidden w-full z-40" :class="scrolled ? 'fixed' : 'relative'">
      <div @click="active = !active" class="inline-block">
        <icon name="hamburger" class="z-40 w-25p h-25p sm:w-30p sm:h-30p fill-current text-white m-10p mb-5p sm:m-15p sm:mb-10p md:hidden"></icon>
      </div>
      <a href="/" title="Williams Square">
        <icon name="logo" class="w-35p h-35p sm:w-75p sm:h-75p mt-5p absolute z-30" :original="true" style="left:50%; transform: translateX(-50%);"></icon>
      </a>
    </div>

    <icon v-else-if="homepage" @click="active = !active" name="hamburger" class="z-30 w-30p h-30p fill-current text-white m-20p z-10 cursor-pointer md:hidden" :class="scrolled ? 'fixed' : 'absolute'"></icon>

    <div v-else class="bg-firefly cursor-pointer md:hidden w-full z-40" :class="scrolled ? 'fixed' : 'relative'">
      <div @click="active = !active" class="inline-block">
        <icon name="hamburger" class="z-10 w-25p h-25p sm:w-30p sm:h-30p fill-current text-white m-10p mb-5p sm:m-15p sm:mb-10p z-10 md:hidden"></icon>
      </div>
      <a href="/" title="Williams Square">
        <icon name="logo" class="w-35p h-35p sm:w-75p sm:h-75p mt-5p absolute z-30" :original="true" style="left:50%; transform: translateX(-50%);"></icon>
      </a>
    </div>

    <!-- mobile navigation -->
    <transition name="uncover">
      <div v-if="active" class="pt-75p bg-firefly fixed z-20 w-full h-screen overflow-hidden md:hidden">
        <ul class="flex flex-col list-reset">
          <li class="py-10p border-b border-pacific pl-40p">
            <a
              :class="homepage ? 'text-pacific-light' : 'text-white'"
              class="uppercase no-underline hover:text-pacific-light"
              href="/">Home</a>
          </li>
          <li class="py-10p border-b border-pacific pl-40p">
            <a
              class="uppercase no-underline hover:text-pacific-light"
              :class="current === 'building' ? 'text-pacific-light' : 'text-white'"
              href="/building">Building</a>
          </li>
          <li class="py-10p border-b border-pacific pl-40p">
            <a
              class="uppercase no-underline hover:text-pacific-light"
              :class="current === 'space' ? 'text-pacific-light' : 'text-white'"
              href="/space">Space</a>
          </li>
          <li class="py-10p border-b border-pacific pl-40p">
            <a
              class="uppercase no-underline hover:text-pacific-light"
              :class="current === 'neighborhood' ? 'text-pacific-light' : 'text-white'"
              href="/neighborhood">Neighborhood</a>
          </li>
          <li class="py-10p border-b border-pacific pl-40p">
            <a
              :class="current === 'contact' ? 'text-pacific-light' : 'text-white'"
              class="uppercase no-underline hover:text-pacific-light"
              href="/contact">Contact</a>
          </li>
        </ul>
      </div>
    </transition>

    <section class="hidden md:block relative w-full overflow-hidden">

      <div :class="homepage ? 'absolute pin-t pin-b pin-l pin-r' : 'fixed'" class="logo-wordmark z-30">
        <a class="logo" href="/">
          <icon name="logo" :original="true" class="pointer-events-"></icon>
        </a>
        <icon v-if="homepage" name="wordmark" class="wordmark" :original="true"></icon>
      </div>

      <div
        v-if="homepage"
        id="header-background"
        data-img-position="center center"
        data-speed="0.85"
        class="jarallax bg-cover bg-center-bottom py-px overflow-hidden relative"
        :style="{backgroundImage: 'url(' + homepageHero + ')'}"
        >
        
        <icon
          width="450vw"
          name="top-wave"
          class="top-wave text-pacific fill-current absolute z-10"
          style="left: -215vw; top: -30vw;"
        ></icon>

        <icon
          width="450vw"
          name="bottom-wave"
          class="bottom-wave text-pacific fill-current absolute z-10"
        ></icon>

        <a href="#" v-scroll-to="{el:'#post-hero', duration: 1000, offset: -64}" id="scroll-btn" class="absolute z-10 pin-b pin-l pin-r mb-25p w-50p h-50p mx-auto hover:opacity-75">
          <icon name="scroll" :original="true"></icon>
        </a>

        <!-- Overlay -->
        <div class="overlay absolute pin-t pin-b pin-l pin-r bg-firefly-dark-90"></div>
      </div>
    </section>

    <nav class="hidden bg-firefly py-20p text-white uppercase fixed pin-t w-full md:flex justify-center z-20">
      <ul class="flex list-reset pl-75p lg:pl-75p text-sm lg:text-base">
        <li class="mx-20p">
          <a
            :class="current === 'building' ? 'text-pacific-light' : 'text-white'"
            class="no-underline hover:text-pacific-light transition-all"
            href="/building">Building</a>
        </li>
        <li class="text-pacific">&bull;</li>
        <li class="mx-20p">
          <a
            :class="current === 'space' ? 'text-pacific-light' : 'text-white'"
            class="no-underline hover:text-pacific-light transition-all" href="/space">Space</a>
        </li>
        <li class="transition-duration-500" :class="scrolled ? 'w-75p lg:w-100p' : 'w-125p lg:w-200p'" style="transition-delay: 200ms;"></li>
        <li class="mx-20p">
          <a
            :class="current === 'neighborhood' ? 'text-pacific-light' : 'text-white'"
            class="no-underline hover:text-pacific-light transition-all"
            href="/neighborhood">Neighborhood</a>
        </li>
        <li class="text-pacific">&bull;</li>
        <li class="mx-20p">
          <a
            :class="current === 'contact' ? 'text-pacific-light' : 'text-white'"
            class="no-underline hover:text-pacific-light transition-all"
            href="/contact">Contact</a>
        </li>
      </ul>
    </nav>

    <homepage-header v-if="homepage" :homepageHero="homepageHero"></homepage-header>

  </div>
</template>

<script>
  export default {
    props: ['homepage', 'homepageHero', 'current'],
    data () {
      return {
        active: false,
        scrolled: false,
        animationFinished: false
      }
    },
    created () {
      window.addEventListener('scroll', this.onScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.onScroll);
    },
    mounted () {
      var animationEvent = whichAnimationEvent(),
          logo = document.querySelector('.logo');

      var logoWrapper = document.querySelector('.logo-wordmark');

      logo.addEventListener(animationEvent, animationEndCallback);

      let _this = this

      function animationEndCallback(event) {
          logo.removeEventListener(animationEvent, animationEndCallback);
          // console.log('Animation finished!')
          logo.style.position="fixed";
          logoWrapper.style.position="fixed";
          logoWrapper.classList.remove("pin-b");
          logoWrapper.classList.remove("pin-t");
          logoWrapper.classList.remove("pin-l");
          logoWrapper.classList.remove("pin-r");

          // if ((window.pageYOffset || document.documentElement.scrollTop) < document.getElementById("post-hero").offsetTop) {
            _this.animationFinished = true
            _this.$emit('animation-finished')
          // }
      }

      // Function from David Walsh: http://davidwalsh.name/css-animation-callback
      function whichAnimationEvent(){
          var t,
              el = document.createElement("fakeelement");

          var transitions = {
              "animation"      : "animationend",
              "OAnimation"     : "oAnimationEnd",
              "MozAnimation"   : "animationend",
              "WebkitAnimation": "webkitAnimationEnd"
          }

          for (t in transitions){
              if (el.style[t] !== undefined){
                  return transitions[t];
              }
          }
      }
    },
    methods: {
      onScroll() {
        if (this.scrolled === false) {
          if ((window.pageYOffset || document.documentElement.scrollTop) > document.getElementById("post-hero").offsetTop) {
            this.scrolled = true
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.uncover-enter,
.uncover-leave-to {
  height: 0;
}

.uncover-enter-active,
.uncover-leave-active {
  transition: height 250ms ease-in-out;
}

.logo {
  top: 20px;
  z-index: 50;
  width: 10vw;
  max-width: 120px;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  transition: all 500ms ease-in-out;
  .scrolled & {
    top: 10px!important;
    width: 60px!important;
  }
}

.homepage {
  #header-background {
    height: 100vh;
    background-position: center top;
    // max-height: 910px;
  }
  nav {
    animation-name: nav;
    @for $i from 0 through 7 {
      li:nth-child(#{$i}) {
        animation-name: nav-item;
        animation-delay: ($i * 75ms);
      }
    }
  }
  .logo {
    animation-name: logo;
    width: 10vw;
    max-width: 120px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%);
  }
  .wordmark {
    animation-name: wordmark;
    width: 30vw;
    position: absolute;
    left: 50%;
    top: 54%;
    transform: translate(-50%);
  }

  &.scrolled:not(.finished) {
    .wordmark,
    .overlay,
    .top-wave,
    .bottom-wave {
      animation-duration: 0s!important;
    }
    nav,
    nav li {
      animation-name: fadedown!important;
      animation-duration: 500ms!important;
    }
    .logo {
      animation-name: scrolledLogo!important;
      animation-duration: 250ms!important;
      top: 10px;
      position: fixed!important;
      z-index: 50!important;
    }
  }

  nav,
  nav li,
  .logo,
  .wordmark,
  .overlay,
  .top-wave,
  .bottom-wave,
  #scroll-btn {
    animation-duration: 6s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }
  .top-wave {
    animation-name: top-wave;
  }
  .bottom-wave {
    animation-name: bottom-wave;
  }
  .overlay {
    opacity: 1;
    animation-name: overlay;
  }

  @keyframes nav-item {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    95% {
      opacity: 1;
    }
  }

  @keyframes nav {
    0% {
      opacity: 0;
    }
    85% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes logo {
    0% {
      transform: translateY(-100%) translateX(-50%);
      opacity: 0;
    }
    25% {
      transform: translateY(-100%) translateX(-50%);
    }
    32% {
      opacity: 0;
    }
    40% {
      transform: translateY(0%) translateX(-50%);
      opacity: 1;
    }
    60% {
      transform: translateY(0%) translateX(-50%);
      top: 40%;
    }
    99% {
      z-index: 0;
    }
    100% {
      transform: translateY(0%) translateX(-50%);
      top: 10px;
      z-index: 50;
    }
  }

  @keyframes wordmark {
    0% {
      transform: translateY(100%) translateX(-50%);
      opacity: 0;
    }
    27% {
      transform: translateY(100%) translateX(-50%);
    }
    35% {
      opacity: 0;
    }
    42% {
      transform: translateY(0%) translateX(-50%);
      opacity: 1;
    }
    60% {
      transform: translateY(0%) translateX(-50%);
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      transform: translateY(100%) translateX(-50%);
      opacity: 0;
    }
  }

  @keyframes bottom-wave {
    0% {
      left: -300vw;
      top: 0vw;
      opacity: 0;
    }
    40% {
      opacity: 1;
      left: -120vw;
      top: 45vw;
    }
    60% {
      opacity: 1;
      left: -120vw;
      top: 45vw;
    }
    100% {
      opacity: 0;
      left: -220vw;
      top: 20vw;
    }
  }

  @keyframes top-wave {
    0% {
      left: -300vw;
      top: 0vw;
      opacity: 0;
    }
    40% {
      opacity: 1;
      left: -215vw;
      top: -30vw;
    }
    60% {
      opacity: 1;
      left: -215vw;
      top: -30vw;
    }
    100% {
      opacity: 0;
      left: -100vw;
      top: 15vw;
    }
  }
  @keyframes overlay {
    0% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadedown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @keyframes scrolledLogo {
    0% {
      transform: translateX(-50%) translateY(-100%);
      opacity: 0!important;
    }
    100% {
      transform: translateX(-50%) translateY(0%);
      opacity: 1!important;
    }
  }

  #scroll-btn {
    animation-name: scroll;
  }

  @keyframes scroll {
    0% { opacity: 0; transform: translateY(20%); }
    80% { opacity: 0; transform: translateY(20%); }
    100% { opacity: 1; transform: translateY(0%); }
  }
}
</style>