<template>
  <div :id="name.toLowerCase()" class="aspect-ratio-square relative bg-center cursor-pointer">
    <div
      :class="`bg-${color}-${opacity} hover:bg-${color}-dark-${opacity}`"
      class="absolute pin-t pin-b pin-l pin-r flex justify-center items-center uppercase text-white text-xl transition-all sm:text-2xl md:text-3xl"
    >
      {{ name }}
    </div>
  </div>
</template>

<script>
  export default {
    props: ['color', 'name'],
    computed: {
      opacity () {
        return this.name == 'Enjoy' ? 70 : 80
      }
    }
  }
</script>

<style scoped>

</style>