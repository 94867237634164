__webpack_public_path__ = 'http://localhost:8080/'

/* eslint-disable */
import Vue from 'vue'
import { jarallax } from 'jarallax'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import vClickOutside from 'v-click-outside'

import Components from './components'
import './app.scss'

// Icons
import './icons-vsvg'
import SvgIcon from 'vue-svgicon'
Vue.use(SvgIcon, {
  tagName: 'icon'
})

// Smooth scroll
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)
Vue.use(vClickOutside)
Vue.use(VuePlyr, {
  plyr: {}
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  delimiters: ['${', '}'],
  mounted () {
    jarallax(document.querySelectorAll('.jarallax'), {
      // speed: 0.5,
      disableParallax: function () {
        return /iPad|iPhone|iPod|Android/.test(navigator.userAgent);
      },
    })
    // sets homepageAnimationFinished to true on smaller screens, homepage wasn't loading
    if (window.innerWidth < 768) {
      this.homepageAnimationFinished = true
    }
  },
  computed: {
    currentPage () {
      return window.page
    }
  },
  data: {
    scrollFinished: false,
    showModal: false,
    homepageAnimationFinished: false
  },
  methods: {
    onDone () {
      this.scrollFinished = true
    },
    resetScroll () {
      this.scrollFinished = false
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
})
