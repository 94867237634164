/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'long-arrow': {
    width: 16,
    height: 16,
    viewBox: '0 0 324.99 19.92',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><path pid="0" _fill="#80bc00" d="M0 9.96h324.99"/><path pid="1" _fill="#92c42e" d="M324.99 9.96l-9.96 9.96h-2.82l8.97-8.96H0V8.97h321.18L312.21 0h2.82l9.96 9.96z"/></g></g>'
  }
})
