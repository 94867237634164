/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo': {
    width: 188,
    height: 133,
    viewBox: '0 0 188 133',
    data: '<defs><path pid="0" id="svgicon_logo_a" d="M0 0h188v133H0z"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_logo_b" _fill="#fff"><use xlink:href="#svgicon_logo_a"/></mask><path pid="1" d="M187.85 8.88c-4.667.393-8.073 1.163-10.22 2.31a11.84 11.84 0 0 0-5.09 6 131.77 131.77 0 0 0-4.95 15.31l-27.08 100.16h-13.34L93.85 39l-31.21 93.63H49.49L19.34 29.72a82.52 82.52 0 0 0-5-13.63c-1.5-2.95-3.26-4.88-5.28-5.82A21.25 21.25 0 0 0 .47 8.88V.05h56.37v8.83c-4.43 0-7.66.36-9.71 1.06-2.05.7-3.07 2.59-3.07 5.67 0 2.053.737 5.54 2.21 10.46l19 65 30.54-91h12.57l32.27 91L157 30a39.45 39.45 0 0 0 1.73-10.08c0-4.667-1.457-7.643-4.37-8.93a30.13 30.13 0 0 0-11.18-2.12V.05h44.64l.03 8.83z" _fill="#FFF" mask="url(#svgicon_logo_b)"/><path pid="2" d="M163.31-10c-1.47 0-49.55 25.65-49.55 25.65-2.09 1.11-7.37 4.1-11 6.05C76.17 35.76 73.17 39 68.05 43.44c-5.12 4.44-9.21 8-11.4 14A23.26 23.26 0 0 0 57 73.86c2.65 6.64 6.84 10.84 13.79 14.13.86.4 7.16 3.46 9.38 5.42.64.56 4.23 3.11 4.2 5.83 0 1.74-2.28 4.67-6.41 7.51-6.51 4.47-27.13 16.6-61.85 35.08L-54 181.82l27.89.57 116.42-65.17c2.34-1.48 7.53-4.36 11.73-6.8 14.61-7.56 17.3-8.76 26.15-12.61a244.4 244.4 0 0 1 24.36-8.65c9.24-2.75 13.48-4.44 16-8.57.41-.66 1.57-4.81-.56-7.64-2.59-3.45-5.64-3.33-17.36-5.88a182.26 182.26 0 0 1-32.58-7.56c-10.76-3.56-14.81-8.23-16.89-10.86-.62-.78-2.57-5 1.78-11.32 3.18-4.62 9.52-8.09 10.88-8.78L185-10h-21.69z" _fill="#0093C9" mask="url(#svgicon_logo_b)"/><path pid="3" _fill="#FFF" mask="url(#svgicon_logo_b)" d="M55.03 55.91l8.94 30.57-10.87.79-8.65-31.13zM45.28 118.29l4.21 14.37h13.25l8.48-25.83zM149.16 59.59l-13.2 49.06 10.43 2.24 15.75-58.25zM143.21.05v8.83L145 9l1.77.16 2.59.38L168.7.09 143.21.05z"/></g>'
  }
})
