<template>
  <div>
    <div class="container lg:flex lg:flex-row-reverse lg:justify-end pt-125p sm:pt-125p md:pt-150p pb-50p lg:pt-100p lg:pb-40p">

      <div class="flex flex-col justify-start">

        <h1 class="hidden lg:inline font-serif leading-none text-4xl lg:text-6xl" v-html="tower.heading"></h1>
        <p class="mt-20p font-light text-lg mb-30p mr-20p lg:mr-0">{{ tower.description }}</p>

        <div class="flex">
          <space-space-stats
            class="lg:hidden"
            :tower="tower"
            color="lagoon">
          </space-space-stats>

          <div class="lg:hidden w-full pl-10p mb-30p">
            <h1 class="mb-8p font-serif leading-none text-3xl sm:text-4xl md:text-5xl lg:text-6xl" v-html="tower.heading"></h1>
            <space-east-image :tower="tower" :selected="selected" class="w-full"></space-east-image>
          </div>
        </div>

        <space-east-floors
          @changeFloor="$emit('changeFloor', $event)"
          class="hidden lg:block"
          :tower="tower"
          :name="name"
          color="lagoon">
        </space-east-floors>
      </div>

      <space-east-floors
        @changeFloor="$emit('changeFloor', $event)"
        class="lg:hidden"
        :tower="tower"
        :name="name"
        color="lagoon">
      </space-east-floors>

      <div class="flex flex-col flex-no-shrink md:pr-50p md:w-1/2">

        <space-east-image class="hidden lg:block" :tower="tower" :selected="selected"></space-east-image>
        <space-space-stats
          class="hidden lg:block"
          :tower="tower"
          color="lagoon">
        </space-space-stats>
      </div>
    </div>

    <section v-if="tower.images.length > 0" class="py-40p bg-white">
      <flickity class="container" color="lagoon">
        <div
          v-for="image in tower.images"
          :key="image"
          class="aspect-ratio-16/9 bg-center bg-cover w-full h-full"
          :style="`background-image: url('${image}');`"
        >
        </div>
      </flickity>
    </section>

  </div>
</template>

<script>
  export default {
    props: ['tower', 'name', 'selected'],
  }
</script>