<template>
  <div>
    <section class="relative md:hidden">

      <icon
        width="70vw"
        name="top-wave-mobile"
        class="absolute pin-t z-10 pin-r md:hidden"
        :original="true"
      ></icon>
      <icon
        width="100vw"
        name="bottom-wave-mobile"
        class="absolute z-10 md:hidden"
        style="top:53vw;"
        :original="true"
      ></icon>

      <div
        class="wrapper bg-cover bg-center py-px"
        style="background-image: url(${homepageHero}); height: 65vw; background-position: center top;">
  
        <div class="flex justify-center mt-25per z-10 relative">
          <icon name="logo-wordmark" :original="true" class="w-1/2"></icon>
        </div>

        <!-- Overlay -->
        <div class="absolute pin-t pin-b pin-l pin-r bg-firefly-dark-70"></div>
      </div>
    </section>

  </div>

</template>

<script>
  export default {
    props: ['homepageHero'],
    mounted() {
      console.log(this.homepageHero);
    }
  }
</script>