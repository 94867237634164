/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wave-3': {
    width: 2292,
    height: 305,
    viewBox: '0 0 2292 305',
    data: '<g _stroke="#00A7E1" stroke-width="3" _fill="none" fill-rule="evenodd"><path pid="0" d="M1889.2 1.8c153.7 36 276.8 81 401.3 131.3M2.1 68.2c81-25.7 161-47.3 239.3-65.6M1715.4 1.7c241.1 39.4 406.5 97.6 574.2 165.2M1.5 102.5C140.7 58.4 276.7 26 407.1 2.7M1438.3 2.2c18.2 1.7 35.3 3.3 51.3 4.8 373.3 36 584.9 107.3 800.3 194.2M1.3 136.8C231 64 452 23.3 653.2 2.2"/><path pid="1" d="M1.1 171.1C647.4-33.9 1223.9 15.6 1489.6 41.2c373.1 36 584.6 107.2 799.9 194"/><path pid="2" d="M1.1 205.2C647.4.2 1223.9 49.7 1489.6 75.3c372.5 35.9 584 107 798.9 193.7"/><path pid="3" d="M1.2 239.4c646.3-205 1222.7-155.5 1488.4-129.9 373 36 584.5 107.2 799.7 194"/></g>'
  }
})
