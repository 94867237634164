<template>
  <div class="flex flex-col">
    <div class="w-full h-full aspect-ratio-square relative bg-cover bg-center" :style="`background-image: url('${background}');`">
      <div
        @mouseover="hovered = true"
        @mouseout="hovered = false"
        class="absolute pin-t pin-b pin-l pin-r flex flex-col items-center justify-center p-2 text-center transition-all transition-duration-200 cursor-default"
        :class="hovered ? 'bg-firefly-80' : 'bg-firefly-40'">


          <h3 class="text-white uppercase italic text-4xl sm:text-3xl md:text-4xl lg:text-3xl text-center" :class="hovered ? 'leading-none' : 'leading-tight'">{{ heading }}</h3>
          <div ref="wrapper" class="flex flex-col items-center justify-center text-center">
            <transition name="fade">
              <div v-show="hovered" class="w-50p border-b border-2 border-lime mb-3 mt-4"></div>
            </transition>
            <transition name="fade">
              <p v-show="hovered" class="text-base sm:text-xs md:text-base lg:text-sm xl:text-xs text-white" v-html="description"></p>
            </transition>
          </div>


      </div>
    </div>
  </div>
</template>

<script>
  import smoothReflow from 'vue-smooth-reflow'

  export default {
    mixins: [smoothReflow],
    props: ['background', 'heading', 'description'],
    mounted () {
      this.$smoothReflow({
        el: this.$refs.wrapper,
        hideOverflow: false,
        transition: 'height 200ms ease'
      })
    },
    data () {
      return {
        hovered: false
      }
    }
  }
</script>

<style scoped>
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 200ms ease;
  }
  .fade-leave-active {
    position: absolute;
  }
</style>