/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wave-5': {
    width: 16,
    height: 16,
    viewBox: '0 0 1520 221',
    data: '<g _fill="none" _stroke="#00a7e1" stroke-width="3"><path pid="0" d="M1519.3 219.6C907.5-16.9 337.1-10.8 2.5 13.5"/><path pid="1" serif:id="Path" d="M1425.2 219.2C848.8 17.7 318.7 24.7 1.4 47.8M1322.8 219.6C787.1 53 301 60.3 2.4 81.9M1204.4 219.5C716 88.4 278.8 96.1 2.5 116.1M1063.8 219.3c-431.5-95.2-812.3-87.1-1061.9-69M886.4 219.4c-357.5-58.7-669.2-50.5-883.9-34.9"/></g>'
  }
})
