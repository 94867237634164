/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'googleplus': {
    width: 35,
    height: 23,
    viewBox: '0 0 35 23',
    data: '<path pid="0" d="M11.134 9.909v3.82h6.322c-.252 1.64-1.91 4.805-6.316 4.805-3.797 0-6.9-3.15-6.9-7.034 0-3.882 3.095-7.032 6.896-7.032 2.165 0 3.615.923 4.44 1.718L18.6 3.278c-1.94-1.816-4.457-2.91-7.46-2.91A11.127 11.127 0 0 0 0 11.5c0 6.147 4.979 11.134 11.134 11.134 6.432 0 10.693-4.518 10.693-10.88 0-.73-.079-1.29-.175-1.845H11.134zm23.866 0h-3.184V6.725h-3.183V9.91H25.45v3.18h3.185v3.186h3.192V13.09H35V9.909z"/>'
  }
})
