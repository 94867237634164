/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 16,
    height: 32,
    viewBox: '0 0 16 32',
    data: '<path pid="0" fill-rule="evenodd" d="M4 32V17.328H0V12h4V7.328C4 3.562 6.375 0 12 0c2.266 0 4 .297 4 .297l-.219 5.031h-3.453C10.297 5.328 10 6.438 10 8v4h6l-.406 5.328H10V32H4z"/>'
  }
})
