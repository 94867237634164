<template>
  <div>
    <!-- <div class="container flex lg:flex-row-reverse pt-125p sm:pt-125p md:pt-150p pb-50p lg:pt-100p lg:pb-40p">

      <div class="flex flex-col flex-1 justify-center lg:pl-35p">

        <h1 class="hidden lg:block font-serif leading-none text-4xl lg:text-5xl xl:text-6xl" v-html="tower.heading"></h1>
        <p class="mt-20p font-light text-lg mb-30p lg:mr-0">{{ tower.description }}</p>

        <div class="hidden lg:flex justify-between">
          <a :href="tower.floorPlan" target="_blank" class="link mb-0 text-base w-1/2 mr-40p" v-if="tower.floorPlan">
            Typical Floor Plan
            <div class="arrow"></div>
          </a>
          <a :href="tower.flyer" target="_blank" class="link w-1/2 text-base"  v-if="tower.flyer">
            Flyer
            <div class="arrow"></div>
          </a>
        </div>

        <div class="flex">

          <space-space-stats
            class="lg:hidden"
            :tower="tower"
            :color="color">
          </space-space-stats>

          <div class="lg:hidden w-full pl-10p mb-30p">

            <h1 class="mb-8p font-serif leading-none text-3xl sm:text-4xl md:text-5xl" v-html="tower.heading"></h1>
            
            <div class="lg:hidden">
              <div
                class="aspect-ratio-4/3 md:aspect-ratio-16/9 mb-20p bg-cover bg-center"
                style="background-image: url('/assets/Space-West_image-2.jpg');">
              </div>

              <div class="flex mt-40p lg:hidden flex-col justify-between lg:flex-row">
                <a class="link mb-30p lg:mb-0 text-base w-3/4 sm:w-1/2 md:w-1/3" v-if="tower.floorPlan">
                  Typical Floor Plan
                  <div class="arrow"></div>
                </a>
                <a class="link w-3/4 sm:w-1/2 md:w-1/3 text-base"  v-if="tower.flyer">
                  Flyer
                  <div class="arrow"></div>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="hidden lg:flex flex-1">
        <div
          class="md:aspect-ratio-16/9 w-full mb-20p bg-cover bg-center"
          style="background-image: url('/assets/Space-West_image-2.jpg');">
        </div>
      </div>

      <space-space-stats
        class="hidden lg:block lg:!mr-30p"
        :tower="tower"
        :color="color">
      </space-space-stats>
    </div> -->

    <div class="container lg:flex lg:flex-row-reverse pt-125p sm:pt-125p md:pt-150p pb-50p lg:pt-100p lg:pb-40p">

      <div class="flex flex-col justify-start">

        <h1 class="hidden lg:inline font-serif leading-none text-4xl lg:text-6xl" v-html="tower.heading"></h1>
        <p class="mt-20p font-light text-lg mb-30p mr-20p lg:mr-0">{{ tower.description }}</p>

        <div class="flex">
          <space-space-stats
            class="lg:hidden"
            :tower="tower"
            color="lime">
          </space-space-stats>

          <div class="lg:hidden w-full pl-10p mb-30p">
            <h1 class="mb-8p font-serif leading-none text-3xl sm:text-4xl md:text-5xl lg:text-6xl" v-html="tower.heading"></h1>
            <space-west-image :tower="tower" :selected="selected" class="w-full"></space-west-image>
          </div>
        </div>

        <space-west-floors
          @changeFloor="$emit('changeFloor', $event)"
          class="hidden lg:block"
          :tower="tower"
          :name="name"
          color="lime">
        </space-west-floors>
      </div>

      <space-west-floors
        @changeFloor="$emit('changeFloor', $event)"
        class="lg:hidden"
        :tower="tower"
        :name="name"
        color="lime">
      </space-west-floors>

      <div class="flex flex-col flex-no-shrink md:pr-50p md:w-1/2">

        <space-west-image class="hidden lg:block" :tower="tower" :selected="selected"></space-west-image>
        <space-space-stats
          class="hidden lg:block"
          :tower="tower"
          color="lime">
        </space-space-stats>
      </div>
    </div>

    <section v-if="tower.images.length > 0" class="py-40p bg-white">
      <flickity class="container" :color="color">
        <div
          v-for="image in tower.images"
          :key="image"
          class="aspect-ratio-16/9 bg-center bg-cover w-full h-full"
          :style="`background-image: url('${image}');`"
        >
        </div>
      </flickity>
    </section>

  </div>
</template>

<script>
export default {
  props: ['tower', 'name', 'color', 'selected'],
}
</script>
