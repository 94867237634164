<template>
  <div class="w-1/2 lg:w-1/4 absolute" ref="wrapper">
    <div
      @mouseover="show = true"
      @mouseout="mouseOut()"
      v-click-outside="onClickOutside"
      :class="[classes, show ? 'h-100p md:h-125p' : 'h-75p']"
      class="pin-l text-white tracking-wide leading-tight overflow-hidden cursor-pointer transition-all">

      <div class="text-center relative" style="top: 50%; transform: translateY(-50%);">
        <h2
          :class="show ? 'text-lg sm:text-lg lg:text-2xl xl:text-3xl': 'text-base sm:text-base md:text-2xl'"
          class="uppercase whitespace-no-wrap font-normal transition-all transition-duration-300 mx-auto"
        >{{ title }}</h2>

        <div ref="wrapper2">
          <transition name="fade">
            <p v-show="show && currentTower === title" class="italic font-thin text-sm sm:text-base px-3 pt-5p xl:text-lg" v-html="subtitle"></p>
          </transition>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import smoothReflow from 'vue-smooth-reflow'

  export default {
    props: ['classes', 'title', 'subtitle', 'default', 'currentTower'],
    data () {
      return {
        show: false
      }
    },
    watch: {
      currentTower (val) {
        if (val === this.title) {
          this.show = true
        }
      }
    },
    mixins: [smoothReflow],
    mounted () {
      if (this.default === true) {
        setTimeout(() => {
          this.show = true
        }, 1500)
      }
      this.$smoothReflow({
        el: this.$refs.wrapper,
        hideOverflow: false,
        transition: 'height 450ms ease'
      })
      this.$smoothReflow({
        el: this.$refs.wrapper2,
        hideOverflow: false,
        transition: 'height 450ms ease'
      })
    },
    methods: {
      mouseOut() {
        if (this.currentTower != this.title) {
          this.show = false
        }
      },
      onClickOutside () {
        setTimeout(() => {
          if (this.currentTower != this.title) {
            this.show = false
          }
        }, 50)
      }
    }
  }
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 500ms ease;
}
.fade-leave-active {
  position: absolute;
  left: 0;
  width: 100%;
}
</style>