/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twitter': {
    width: 33,
    height: 27,
    viewBox: '0 0 33 27',
    data: '<path pid="0" fill-rule="evenodd" d="M32.997 3.158A13.71 13.71 0 0 1 29.63 6.67v.886c0 8.942-6.8 19.254-19.238 19.254-3.818 0-7.411-.838-10.392-2.755.532.08 1.063.113 1.611.113 3.158 0 6.107-1.37 8.394-3.207-2.932-.032-5.461-2.03-6.3-4.704.42.08.839.129 1.257.129a6.77 6.77 0 0 0 1.805-.242 6.763 6.763 0 0 1-5.43-6.638v-.08c.919.499 1.95.805 3.062.837-1.837-1.224-3.03-3.287-3.03-5.607 0-1.256.355-2.288.919-3.286 3.367 4.092 8.33 6.638 13.937 6.944-.113-.484-.145-1.031-.145-1.563A6.739 6.739 0 0 1 22.83 0c1.95 0 3.706.838 4.979 2.127 1.514-.306 2.98-.87 4.27-1.644a6.712 6.712 0 0 1-2.981 3.754 13.203 13.203 0 0 0 3.899-1.08z"/>'
  }
})
