<template>
  <div>
    <div class="flex flex-col lg:flex-row" v-if="name === 'West Tower'">
      <a class="link mb-30p lg:mb-0 text-base lg:mr-40p" v-if="tower.floorPlan">
        Typical Floor Plan
        <div class="arrow"></div>
      </a>
      <a class="link pr-25p text-base"  v-if="tower.flyer">
        Flyer
        <div class="arrow"></div>
      </a>
    </div>

    

    <div class="flex flex-row flex-wrap" v-if="tower.floors">
      
        <div
          v-for="(floor, index) in tower.floors"
          :key="floor.floorNumber"
          class="w-125p text-white mr-8p"
          :class="`wrapper-${index}`"
          >
            <div
              @click="toggleDropdown(floor)"
              class="px-15p pb-5p pt-3p text-lg text-center cursor-pointer relative z-10 mb-15p"
              :class="[`bg-${color} border-2 hover:bg-${color}-dark`, activeDropdown === floor ? 'border-lime' : 'border-transparent']">
              {{ floor.floorNumber }}
            </div>
            <transition name="dropdown">
              <div v-if="activeDropdown === floor" class="flex relative z-0">
                <div class="flex flex-col">
                  <p class="whitespace-no-wrap mb-10p link">{{ floor.spaceAvailable }}</p>
                  <a v-if="floor.floorPlan" :href="floor.floorPlan" target="_blank" class="link mb-15p text-base">
                    Floor Plan
                    <div class="arrow pt-5p" :class="color"></div>
                  </a>
                  <a v-if="floor.flyer" :href="floor.flyer" target="_blank" class="link mb-25p text-base">
                    Flyer
                    <div class="arrow pt-5p" :class="color"></div>
                  </a>
                </div>
              </div>
            </transition>
        
      </div>
    </div>

  </div>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'

export default {
  props: ['tower', 'name', 'color'],
  mixins: [smoothReflow],
  data() {
    return {
      activeDropdown: null,
    }
  },
  methods: {
    toggleDropdown(floor) {
      this.activeDropdown === floor.floorNumber ? (this.activeDropdown = '') : (this.activeDropdown = floor)
      this.$emit('changeFloor', this.activeDropdown)
    },
  },
  mounted() {
    // if (this.tower.floors && this.tower.floors.length) {
    //   const firstFloor = this.tower.floors[0]
    //   this.activeDropdown = firstFloor
    //   this.$emit('changeFloor', firstFloor)
    // }

    if (this.tower.floors && this.tower.floors.length) {
      let i
      for (i = 0; i < this.tower.floors.length; i++) {
        this.$smoothReflow({
          el: `.wrapper-${i}`,
          hideOverflow: true,
          transition: 'height 100ms ease',
        })
      }
    }
  },
  computed: {
    availability() {
      const floors = this.tower.floors.map(floor => floor.available.value)
      const uniqueAvailability = Array.from(new Set(floors))
      return uniqueAvailability
    },
  },
}
</script>

<style scoped>
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}

.dropdown-enter-to,
.dropdown-leave {
  opacity: 1;
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 100ms ease-in-out;
}
.dropdown-leave-active {
  position: absolute;
}
</style>
