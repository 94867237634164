/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hamburger': {
    width: 15,
    height: 13,
    viewBox: '0 0 15 13',
    data: '<g _fill="#FFF" fill-rule="nonzero"><path pid="0" d="M0 .84V0h15v.84zM0 6.84V6h15v.84zM0 12.84V12h15v.84z"/></g>'
  }
})
