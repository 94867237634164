/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wave-4': {
    width: 908,
    height: 322,
    viewBox: '0 0 908 322',
    data: '<g _stroke="#00A7E1" stroke-width="3" _fill="none" fill-rule="evenodd"><path pid="0" d="M906.1 320.2C664.8 227.1 439.7 112 1.1 2.3M906.5 286.1c-216.8-83.7-420.4-185-777.3-284.3M906.5 252C714.8 178 533.4 90.2 247.1 2.2M905.4 217.3C739.1 153.1 580.4 78.4 354 2.3M906.7 183.6C765 129 629.1 66.8 452.3 2.6M906.9 149.5c-116.4-44.9-229-94.9-363.6-147"/></g>'
  }
})
