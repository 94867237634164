<template>
  <div>

    <div class="flex flex-row flex-wrap">
      
        <div class="w-1/2" v-for="timePeriod in availability" :key="timePeriod">
          <div class="flex flex-shrink mb-15p px-5p justify-center lg:justify-start">
            <h2 class="uppercase font-serif text-2xl text-center">
              Available<br>{{ timePeriod }}
            </h2>
          </div>

          <div
            v-for="(floor, index) in tower.floors"
            v-if="floor.available.value === timePeriod"
            :key="floor.floorNumber"
            class="text-white mr-8p flex flex-col items-center lg:items-start"
            :class="`wrapper-${index}`"
            >
              <div
                @click="toggleDropdown(floor)"
                class="px-10p sm:px-20p pb-5p pt-3p text-lg text-center cursor-pointer relative z-10 mb-15p w-125p sm:w-150p"
                :class="[`bg-${color} border-2 hover:bg-${color}-dark`, activeDropdown === floor ? 'border-lime' : 'border-transparent']">
                {{ floor.floorNumber }}
              </div>
              <transition name="dropdown">
                <div v-if="activeDropdown === floor" class="flex relative z-0 w-125p sm:w-150p">
                  <div class="flex flex-col">
                    <p class="whitespace-no-wrap mb-10p link">{{ floor.spaceAvailable }}</p>
                    <div class="flex">
                      <div v-if="floor.floorPlan" class="flex-1 relative mb-15p">
                        <a :href="floor.floorPlan" target="_blank" class="link text-sm">
                          Floor Plan
                          <div :class="color" class="arrow pt-5p"></div>
                        </a>
                      </div>
                      <div v-if="floor.flyer"
                        class="flex-1 relative mb-25p"
                        :class="floor.floorPlan ? 'ml-30p' : ''"
                      >
                        <a :href="floor.flyer" target="_blank" class="link text-sm pr-15p sm:pr-30p">
                          Flyer
                          <div :class="color" class="arrow pt-5p"></div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
        </div>

    </div>

  </div>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'

export default {
  props: ['tower', 'name', 'color'],
  mixins: [smoothReflow],
  data() {
    return {
      activeDropdown: null,
    }
  },
  methods: {
    toggleDropdown(floor) {
      this.activeDropdown === floor ? (this.activeDropdown = '') : (this.activeDropdown = floor)
      this.$emit('changeFloor', this.activeDropdown)
    },
  },
  mounted() {
    // if (this.tower.floors && this.tower.floors.length) {
    //   const firstFloor = this.tower.floors[0]
    //   this.activeDropdown = firstFloor
    //   this.$emit('changeFloor', firstFloor)
    // }

    if (this.tower.floors && this.tower.floors.length) {
      let i
      for (i = 0; i < this.tower.floors.length; i++) {
        this.$smoothReflow({
          el: `.wrapper-${i}`,
          hideOverflow: true,
          transition: 'height 100ms ease',
        })
      }
    }
  },
  computed: {
    availability() {
      const floors = this.tower.floors.map(floor => floor.available.value)
      const uniqueAvailability = Array.from(new Set(floors))
      return uniqueAvailability
    },
  },
}
</script>

<style scoped>
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}

.dropdown-enter-to,
.dropdown-leave {
  opacity: 1;
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 100ms ease-in-out;
}
.dropdown-leave-active {
  position: absolute;
}
</style>
