<template>
  <div>
    <div class="relative">

      <!-- <transition name="fade">
        <div class="relative aspect-ratio-16/9" v-if="tower === 'West Tower'">
          <transition name="lights-off">
            <section
              v-show="!lightsOn"
              class="absolute pin-t pin-b pin-l pin-r bg-center bg-cover"
              style="background-image: url('/assets/Space-West_image-1.jpg');"
            ></section>
          </transition>

          <transition name="lights-on">
            <section
              v-show="lightsOn"
              class="absolute pin-t pin-b pin-l pin-r bg-center bg-cover"
              style="background-image: url('/assets/Space-West_image-2.jpg');"
            ></section>
          </transition>
        </div>
      </transition> -->
      
      <transition name="fade">
        <section
          v-if="tower === 'West Tower'"
          class="aspect-ratio-16/9 bg-center bg-cover"
          :style="`background-image: url(${westTower.heroImage});`"
        ></section>
      </transition>

      <transition name="fade">
        <section
          v-if="tower === 'Central Tower'"
          class="aspect-ratio-16/9 bg-center bg-cover"
          :style="`background-image: url(${centralTower.heroImage});`"
        ></section>
      </transition>
      <!-- <transition name="fade">
        <div class="relative aspect-ratio-16/9" v-if="tower === 'Central Tower'">
          <transition name="floor-fade">
            <section
              v-show="tower === 'Central Tower'"
              class="absolute pin-t pin-b pin-l pin-r bg-center bg-cover"
              style="background-image: url('/assets/Space-Central_image-1.jpg');"
            ></section>
          </transition>
        </div>
      </transition> -->

      <transition name="fade">
        <section
          v-if="tower === 'East Tower'"
          class="aspect-ratio-16/9 bg-center bg-cover"
          :style="`background-image: url(${eastTower.heroImage});`"
        ></section>
      </transition>

      <!-- <transition name="fade">
        <div class="relative aspect-ratio-16/9" v-if="tower === 'East Tower'">
          <transition name="floor-fade">
            <section
              v-show="tower === 'East Tower'"
              class="absolute pin-t pin-b pin-l pin-r bg-center bg-cover"
              style="background-image: url('/assets/Space-East_image.jpg');"
            ></section>
          </transition>
        </div>
      </transition> -->

      <transition name="fade">
        <section
          v-if="tower === 'Chamber Tower'"
          class="aspect-ratio-16/9 bg-center bg-cover"
          :style="`background-image: url(${chamberTower.heroImage});`"
        ></section>
      </transition>
    </div>

    <div class="flex items-center relative z-10 lg:hidden">
      <div class="h-50p">
        <space-space-block
          title="Chamber Tower"
          :subtitle="getBlockSubtitle(chamberTower)"
          class="bg-elm pin-b lg:pin-none"
          :default="false"
          :currentTower="tower"
          @click.native="tower = 'Chamber Tower'"
        ></space-space-block>

        <space-space-block
          title="East Tower"
          :subtitle="getBlockSubtitle(eastTower)"
          class="bg-lagoon pin-b lg:pin-none push-1/2 lg:push-1/4"
          :default="false"
          :currentTower="tower"
          @click.native="tower = 'East Tower'"
        ></space-space-block>
      </div>

      <div class="h-50p">
        <space-space-block
          title="Central Tower"
          :subtitle="getBlockSubtitle(centralTower)"
          class="bg-pacific mt-50p lg:mt-0 lg:push-1/2"
          :default="false"
          :currentTower="tower"
          @click.native="tower = 'Central Tower'"
        ></space-space-block>

        <space-space-block
          title="West Tower"
          :subtitle="getBlockSubtitle(westTower)"
          class="bg-lime mt-50p push-1/2 lg:mt-0 lg:push-3/4"
          :default="true"
          :currentTower="tower"
          @click.native="tower = 'West Tower'"
        ></space-space-block>
      </div>
    </div>

    <div class="hidden items-center relative lg:flex z-10">
      <space-space-block
        title="Chamber Tower"
        :subtitle="getBlockSubtitle(chamberTower)"
        class="bg-elm pin-b lg:pin-none"
        :default="false"
        :currentTower="tower"
        @click.native="tower = 'Chamber Tower'"
      ></space-space-block>

      <space-space-block
        title="East Tower"
        :subtitle="getBlockSubtitle(eastTower)"
        class="bg-lagoon pin-b lg:pin-none push-1/2 lg:push-1/4"
        :default="false"
        :currentTower="tower"
        @click.native="tower = 'East Tower'"
      ></space-space-block>

      <space-space-block
        title="Central Tower"
        :subtitle="getBlockSubtitle(centralTower)"
        class="bg-pacific mt-75p lg:mt-0 lg:push-1/2"
        :default="false"
        :currentTower="tower"
        @click.native="tower = 'Central Tower'"
      ></space-space-block>

      <space-space-block
        title="West Tower"
        :subtitle="getBlockSubtitle(westTower)"
        class="bg-lime mt-75p push-1/2 lg:mt-0 lg:push-3/4"
        :default="true"
        :currentTower="tower"
        @click.native="tower = 'West Tower'"
      ></space-space-block>
    </div>

    <div class="bg-firefly text-white" id="post-hero">
      <div class="relative">
        <transition name="slide-fade">
          <space-west-content
            @changeFloor="selected = $event"
            v-if="tower === 'West Tower'"
            :name="'West Tower'"
            :tower="westTower"
            color="lime"
            :selected="selected"
          >
          </space-west-content>
        </transition>

        <transition name="slide-fade">
          <space-east-content
            @changeFloor="selected = $event"
            v-if="tower === 'East Tower'"
            :name="'East Tower'"
            :tower="eastTower"
            :selected="selected"
          >
          </space-east-content>
        </transition>

        <transition name="slide-fade">
          <space-central-content
            @changeFloor="selected = $event"
            v-if="tower === 'Central Tower'"
            :name="'Central Tower'"
            :tower="centralTower"
            :selected="selected"
          >
          </space-central-content>
        </transition>

        <transition name="slide-fade">
          <space-chamber-content
            @changeFloor="selected = $event"
            v-if="tower === 'Chamber Tower'"
            :tower="chamberTower"
          >
          </space-chamber-content>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'

export default {
  props: ['westTower', 'chamberTower', 'eastTower', 'centralTower'],
  mixins: [smoothReflow],
  mounted() {
    this.$smoothReflow({
      el: this.$refs.wrapper,
      hideOverflow: false,
      transition: 'height 200ms ease',
    })
    setTimeout(() => {
      this.lightsOn = true
    }, 1500)
  },
  data() {
    return {
      tower: 'West Tower',
      lightsOn: false,
      selected: null,
      visited: false,
    }
  },
  watch: {
    tower() {
      this.selected = null
    },
  },
  methods: {
    getBlockSubtitle(tower) {
      const selectedText = this.selected ? `${this.selected.floorNumber} / ${this.selected.spaceAvailable}` : null
      return selectedText || tower.linkText
    },
  },
}
</script>

<style lang="scss" scoped>
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(25px);
}

.slide-fade-enter-to,
.slide-fade-leave {
  opacity: 1;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 450ms ease-in-out;
}

.slide-fade-leave-active {
  position: absolute;
  width: 100%;
  top: 0;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 200ms ease;
}
.fade-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.lights-off-leave-to {
  opacity: 0;
}
.lights-off-leave-active {
  transition: all 500ms ease 1500ms;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.lights-on-enter {
  opacity: 0;
}
.lights-on-enter-active {
  transition: all 500ms ease;
}

.floor-fade-enter,
.floor-fade-leave-to {
  opacity: 0;
}
.floor-fade-enter-active {
  transition: all 200ms ease;
}
.floor-fade-leave-active {
  transition: all 200ms ease 200ms;
}
.floor-fade-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
</style>
