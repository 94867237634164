<template>
  <div class="container my-30p">
    <div @click="togglePlay()" class="relative group cursor-pointer">
      <vue-plyr
        ref="player"
        :options="{ controls: ['play', 'progress'] }"
        :emit="['playing', 'pause', 'ended']"
        @ended="showPlayBtn = true;"
      >
        <div class="plyr__video-embed">
          <iframe
            src="https://player.vimeo.com/video/280993422?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"
            allowfullscreen allowtransparency allow="autoplay">
          </iframe>
        </div>
      </vue-plyr>
  
      <transition name="fade">
        <a v-if="showPlayBtn" class="link absolute z-10 pin-t pin-b pin-r flex items-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
          Take a virtual tour.
          <icon
            name="circle-arrow"
            class="
              h-50p -mr-30p ml-15p 
              sm:h-75p sm:-mr-45p
              lg:-mr-60p lg:pr-3p lg:h-100p xl:h-100p" :original="true"></icon>
        </a>
      </transition>

      <transition name="fade">
        <div v-if="showPlayBtn" class="bg-firefly absolute pin-t pin-b pin-l pin-r bg-firefly-70 group-hover:bg-firefly-50 transition-all transition-duration-200"></div>
      </transition>

    </div>
  </div>
</template>

<script>
  export default {
    props: ['scrollFinished'],
    mounted() {
      this.player = this.$refs.player.player
      setTimeout(() => {
        this.player.toggleControls(false)
      }, 1000)
      // this.player.toggleControls(false)
    },
    data () {
      return {
        player: null,
        showPlayBtn: true
      }
    },
    watch: {
      scrollFinished () {
        this.togglePlay()
        this.$emit('reset-scroll')
      }
    },
    methods: {
      togglePlay () {
        if (this.showPlayBtn === false) {
          this.player.pause()
          setTimeout(() => {
            this.showPlayBtn = true
          }, 300)
        } else {
          this.player.play()
          setTimeout(() => {
            this.showPlayBtn = false
          }, 300)
        }
      }
    }
  }
</script>

<style scoped>
  .fade-enter-active {
    transition: all .3s ease;
  }
  .fade-leave-active {
    transition: all .3s ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-to,
  .fade-leave {
    opacity: 1;
  }
</style>