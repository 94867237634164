/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pinterest': {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35',
    data: '<path pid="0" d="M0 17.432C0 24.57 4.292 30.702 10.434 33.4c-.049-1.218-.008-2.679.304-4.003l2.243-9.5s-.557-1.112-.557-2.757c0-2.583 1.497-4.513 3.362-4.513 1.586 0 2.352 1.191 2.352 2.617 0 1.594-1.017 3.978-1.54 6.187-.437 1.849.927 3.357 2.752 3.357 3.302 0 5.527-4.242 5.527-9.268 0-3.82-2.573-6.68-7.254-6.68-5.288 0-8.582 3.943-8.582 8.348 0 1.519.448 2.59 1.15 3.419.322.38.367.534.25.972-.084.32-.276 1.092-.355 1.398-.116.442-.474.6-.873.437-2.436-.994-3.57-3.662-3.57-6.66 0-4.952 4.176-10.89 12.459-10.89 6.655 0 11.036 4.816 11.036 9.986 0 6.839-3.802 11.947-9.406 11.947-1.882 0-3.653-1.017-4.259-2.172 0 0-1.012 4.016-1.226 4.792-.37 1.344-1.093 2.687-1.755 3.734 1.568.463 3.224.715 4.94.715 9.627 0 17.432-7.805 17.432-17.433C34.864 7.805 27.059 0 17.433 0 7.805 0 0 7.805 0 17.432z"/>'
  }
})
