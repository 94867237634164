<template>
  <div>
    <div class="hidden container lg:flex lg:flex-row-reverse pt-125p sm:pt-125p md:pt-150p pb-50p lg:pt-100p lg:pb-40p">

      <div class="flex flex-col justify-center">

        <h1 class="font-serif leading-none text-4xl lg:text-6xl" v-html="tower.heading"></h1>
        <p class="mt-20p pr-12 font-light text-lg mb-30p mr-20p lg:mr-0">{{ tower.description }}</p>

        <space-space-stats class="lg:hidden ml-15p" :tower="tower" :color="color"></space-space-stats>

        <!-- <space-space-central-floors
          v-if="name === 'Central Tower'"
          @changeFloor="$emit('changeFloor', $event)"
          class="hidden lg:block"
          :tower="tower"
          :name="name"
          :color="color">
        </space-space-central-floors>

        <space-space-floors
          v-else
          @changeFloor="$emit('changeFloor', $event)"
          class="hidden lg:block"
          :tower="tower"
          :name="name"
          :color="color">
        </space-space-floors> -->

      </div>

      <!-- <space-space-floors @changeFloor="$emit('changeFloor', $event)" class="lg:hidden" :tower="tower" :name="name" :color="color"></space-space-floors> -->

      <div v-if="tower === 'Central Tower'" class="flex flex-col flex-no-shrink md:pr-50p md:w-1/2">
        <div class="mb-20p">
          <div class="relative aspect-ratio-16/9">
            <div v-for="floor in tower.floors" :key="floor.floorNumber">
              <transition name="floor-fade">
                <div
                  v-show="selected === floor.floorNumber"
                  class="absolute pin-t pin-b pin-l pin-r bg-center bg-cover"
                  :style="`background-image: url('${floor.buildingImage}');`">
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>

      <space-space-stats class="hidden lg:block" :tower="tower" :color="color"></space-space-stats>
    </div>

    <!-- new mobile layout -->
    <div class="container flex lg:hidden pt-125p sm:pt-125p md:pt-150p pb-50p lg:pt-100p lg:pb-40p">

      <div class="flex flex-col justify-center">
        <h1 class="font-serif leading-none text-4xl lg:text-6xl" v-html="tower.heading"></h1>
        <p class="mt-20p font-light text-lg mb-30p mr-20p lg:mr-0">{{ tower.description }}</p>

        <space-space-stats class="ml-15p" :tower="tower" :color="color"></space-space-stats>

        <space-space-floors @changeFloor="$emit('changeFloor', $event)" class="mt-15p" :tower="tower" :name="name" :color="color"></space-space-floors>
      </div>
    </div>

    <section v-if="tower.images.length > 0" class="py-40p bg-white">
      <flickity class="container" :color="color">
        <div
          v-for="image in tower.images"
          :key="image"
          class="aspect-ratio-16/9 bg-center bg-cover w-full h-full"
          :style="`background-image: url('${image}');`"
        >
        </div>
      </flickity>
    </section>

  </div>
</template>

<script>
  export default {
    props: ['tower', 'name', 'color', 'selected'],
  }
</script>