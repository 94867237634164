<template>
  <div class="swiper-container absolute h-full">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <div v-for="(logo, index) in images" :key="index" class="swiper-slide h-auto py-25p">
        <img class="h-auto " :src="logo.logo" style="max-width: 60%">
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper from 'swiper'
  export default {
    props: ['images'],
    mounted () {
      var mySwiper = new Swiper ('.swiper-container', {
        // Optional parameters
        direction: 'vertical',
        loop: false,
        loopedSlides: 20,
        slidesPerView: 'auto',
        freeMode: true,
        autoplay: {
          delay: 2000
        }
      })
    }
    
  }
</script>

<style scoped>
.swiper-container {
  /* width: 600px;
  height: 300px; */
}
</style>