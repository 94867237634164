<template>
  <ul class="list-reset lg:mr-75p lg:flex-no-shrink">
    <li class="text-base sm:text-xl md:text-2xl mb-15p" v-for="stat in tower.stats" :key="stat.stats">
      {{ stat.stats }}
      <div
        :class="`border-${color}`"
        class="border-b-2 w-35p -ml-15p mt-5p">
      </div>
    </li>
  </ul>
</template>

<script>
  export default {
    props: ['tower', 'color']
  }
</script>

<style scoped>

</style>