<template>
  <div>
    <div style="transform:scale(1); " class="container lg:flex lg:flex-row-reverse pt-125p sm:pt-125p md:pt-150p pb-50p lg:pt-100p lg:pb-40p">

      <div class="flex flex-col justify-center">

        <h1 class="font-serif leading-none text-4xl lg:text-6xl" v-html="tower.heading"></h1>
        <p class="mt-20p pr-12 font-light text-lg mb-30p mr-20p lg:mr-0">{{ tower.description }}</p>

        <space-space-stats
          class="lg:hidden ml-15p mb-15p"
          :tower="tower"
          color="elm">
        </space-space-stats>

        <space-space-floors
          @changeFloor="$emit('changeFloor', $event)"
          :tower="tower"
          :name="name"
          color="elm">
        </space-space-floors>

      </div>

      <space-space-stats class="hidden lg:block" :tower="tower" color="elm"></space-space-stats>
    </div>

  </div>
</template>

<script>
  export default {
    props: ['tower', 'name', 'color'],
    data () {
      return {
        reflowCount: '1'
      }
    },
    methods: {
      reflow() {
        
      }
    }
  }
</script>