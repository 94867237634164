<template>
  <div class="relative">
    <div ref="carousel" class="carousel">
      <slot></slot>
    </div>

    <div class="flex relative justify-between">
      <div @click="goPrevious" class="w-150p cursor-pointer relative py-20p items-center -ml-10 -mt-32 hidden md:flex" :class="previousClass">
        <div class="arrow left" :class="color"></div>
      </div>

      <div @click="goPrevious" class="w-50p cursor-pointer relative py-20p flex items-center -ml-8 -mt-16 md:hidden" :class="previousClass">
        <icon name="short-arrow" dir="down" class="w-50p h-50p fill-current" :class="`text-${color}`"></icon>
      </div>
  
      <div @click="goNext" class="w-150p cursor-pointer relative py-20p items-center -mr-10 -mt-32 hidden md:flex" :class="nextClass">
        <div class="arrow right" :class="color"></div>
      </div>

      <div @click="goNext" class="w-50p cursor-pointer relative py-20p flex items-center -mr-8 -mt-16 md:hidden" :class="nextClass">
        <icon name="short-arrow" class="w-50p h-50p fill-current" :class="`text-${color}`"></icon>
      </div>
    </div>
  </div>
</template>

<script>
  import Flickity from 'flickity'
  import 'flickity-imagesloaded'

  export default {
    props: {
      color: {
        default: 'lime'
      }
    },
    mounted () {
      this.flkty = new Flickity(this.$refs.carousel, {
        "imagesLoaded": true,
        "percentPosition": false,
        "pageDots": false,
        "prevNextButtons": false
      })
      this.getNextClass()
      this.getPreviousClass()
    },
    data () {
      return {
        flkty: null,
        previousClass: '',
        nextClass: ''
      }
    },
    methods: {
      goNext() {
        this.flkty.next()
        this.getNextClass()
        this.getPreviousClass()
      },
      goPrevious() {
        this.flkty.previous()
        this.getNextClass()
        this.getPreviousClass()
      },
      getPreviousClass () {
        if (this.flkty.selectedIndex === 0) {
          this.previousClass = 'opacity-25'
        } else {
          this.previousClass = ''
        }
      },
      getNextClass () {
        if (this.flkty.selectedIndex === this.flkty.slides.length - 1) {
          this.nextClass = 'opacity-25'
        } else {
          this.nextClass = ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .arrow {
    border-bottom-width: 2px;
    padding-top: 0;
    top: inherit;
    &:before,
    &:after {
      height: 2px;
    }
    &.left {
      left: 0%;
      transform: rotate(180deg);
    }
  }
</style>