/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wave-1': {
    width: 2085,
    height: 191,
    viewBox: '0 0 2085 191',
    data: '<g _stroke="#00A7E1" stroke-width="3" _fill="none" fill-rule="evenodd"><path pid="0" d="M2084.1 2.2C1398.4 245.2 776.4 192 497.3 165.1 292.5 145.3 136.3 115 1.5 77.2"/><path pid="1" d="M1981.5 2.4c-644.6 203.4-1219 154.1-1484.1 128.5-205-19.8-361.2-50.2-496.1-88"/><path pid="2" d="M1866.5 2.1C1269.2 165.8 746.6 120.8 497.3 96.7c-205-19.8-361.2-50.2-496-88"/><path pid="3" d="M1728.6 2.4c-539 122.3-1001.4 82.3-1231.3 60.1C345.6 47.9 220.6 27.4 111.2 2.4"/><path pid="4" d="M1559.2 2C1093.8 82.2 702.7 48.2 497.3 28.4c-75.3-7.3-144-15.9-207.4-25.9M1298.5 2.4c-290.5 27.4-537.7 14.5-708 .2"/></g>'
  }
})
