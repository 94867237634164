/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'short-arrow': {
    width: 16,
    height: 16,
    viewBox: '0 0 37.05 31.74',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><path pid="0" _fill="#80bc00" d="M0 15.87h37.05"/><path pid="1" _fill="#92c42e" d="M37.05 15.87L21.19 31.74H16.7l14.28-14.28H0v-3.18h30.98L16.7 0h4.49l15.86 15.87z"/></g></g>'
  }
})
